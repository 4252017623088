import styled, { css } from "styled-components";

import Colors from "./Colors";

export const Container = styled.div`
  margin: auto;
  max-width: 750px;

  h3 {
    margin-left: 30px;
  }
`;

export const Content = styled.div`
  margin: 0 30px;
`;

export const Section = styled.section`
  h3 {
    color: ${Colors.nord12};
    margin-bottom: 10px;
  }

  h4 {
    margin: 10px 0 10px 0;
  }

  h5 {
    margin: 0 0 10px 0;
  }

  h6 {
    margin: 10px 0;
  }

  p,
  dl {
    margin: 0;
  }

  dt {
    font-weight: bold;
  }
  dd {
    margin: 0 0 10px 0;
  }
`;

export const Link = styled.a`
  color: ${Colors.nord10};
  text-decoration: none;
`;

export const List = styled.ul<{ inLine?: boolean }>`
  padding: 0;
  list-style: none;
  margin: 0;

  ${(props): ReturnType<typeof css> | false | undefined =>
    props.inLine &&
    css`
      display: flex;
      justify-content: start;
      flex-wrap: wrap;

      > li {
        margin-right: 30px;
      }
    `}
`;

export const EmploymentHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
