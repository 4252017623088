import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import { Container, Content, Link } from "./index";
import Colors from "./Colors";

const Footer: React.FC = () => {
  const { site } = useStaticQuery(
    graphql`
      query AuthorData {
        site {
          siteMetadata {
            author
          }
        }
      }
    `
  );

  return (
    <Container>
      <Content>
        <Ftr>
          <p>
            {site.siteMetadata.author} © {new Date().getFullYear()}
          </p>
          <p>
            Built with <Link href="https://www.gatsbyjs.org">Gatsby</Link>
          </p>
        </Ftr>
      </Content>
    </Container>
  );
};

const Ftr = styled.footer`
  margin-top: 20px;
  border-top: 1px solid ${Colors.nord4};

  p {
    margin: 10px 0;
  }
`;

export default Footer;
