import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import { Container, Content } from "./index";
import Colors from "./Colors";

const Header: React.FC<Props> = () => {
  const {
    dataJson: { basics },
  } = useStaticQuery<{
    dataJson: { basics: { name: string; label: string } };
  }>(graphql`
    query HeaderData {
      dataJson {
        basics {
          name
          label
        }
      }
    }
  `);

  return (
    <>
      <Hdr>
        <Container>
          <Content>
            <h1>{basics.name}</h1>
            <h2>{basics.label}</h2>
          </Content>
        </Container>

        <GlobalStyle />
      </Hdr>
    </>
  );
};

type Props = {
  title?: string;
  subheading?: string;
};

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto Condensed', sans-serif;
    margin: 0;
  }
`;

const Hdr = styled.header`
  color: ${Colors.nord3};
  background: ${Colors.background};
  padding: 50px 0;

  h1,
  h2 {
    margin: 0 0 10px 0;
  }
`;

export default Header;
